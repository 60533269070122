import { Form, message } from "antd";
import { useEffect, useState } from "react";
import { useRequest } from "ahooks";
import { postTranslateLingoSelectApi } from "@/services";
import { postSettingListApi } from "@/services/setting";
import { SettingListProps } from "@/services/setting/props";
import { v4 } from "uuid";
import { SettingFormProps, SettingModalProps } from "./props";
import { useVideoStore } from "@/store";

const initialData = {
  createTask: {
    text_video_speaking_speed: 0,
    text_video_subtitle: [""],
    text_video_bgc_music: -1,
    text_video_voice: [1, 110],
  },
  createSelectTask: {
    text_select_video_speaking_speed: 0,
    text_select_video_subtitle: [""],
    text_select_video_bgc_music: -1,
    text_select_video_voice: [1, 110],
  },
  dubbingTask: { dubbing_speaking_speed: 0, dubbing_voice: [1, 110] },
  createPPTTask: {
    ppt_speaking_speed: 0,
    ppt_subtitle: [""],
    ppt_bgc_music: -1,
    ppt_voice: [1, 110],
  },
  translateTask: {
    translate_speaking_speed: 0,
    translate_voice: [1, 110],
    translate_model: 0,
    translate_term: "null",
    translate_subtitle: [""],
  },
  videoToTextTask: {
    video_to_text_voice: "zh",
    video_to_text_term: "null",
  },
};

const useAction = (props: SettingModalProps) => {
  const [languageList] = useVideoStore((state) => [state.languageList]);
  const [form] = Form.useForm<SettingFormProps>();
  const [data, setData] = useState<SettingListProps>();
  const [termSelectOptionsValues, setTermSelectOptionsValues] = useState<
    string[]
  >([]);
  const [newItemName, setNewItemName] = useState("");

  useRequest(postTranslateLingoSelectApi, {
    defaultParams: [{ provider_id: 1 }],
    onSuccess(result) {
      setTermSelectOptionsValues(result.data.model);
    },
  });

  const { loading: updateLoading, run: updateSetting } = useRequest(
    postSettingListApi,
    {
      manual: true,
      onSuccess() {
        message.success("修改成功");
        props.onOk?.();
        props.onClose();
      },
    }
  );

  const onFormValuesChange = (newData: SettingListProps, newId?: string) => {
    if (newData && newId) {
      let tempData: { [key: string]: any } = {};
      newData.data?.forEach((item) => {
        if (item.id === newId) {
          Object.values(item.info).forEach((value) => {
            tempData = { ...tempData, ...value };
          });
        }
      });

      form.setFieldsValue({ using: newId, ...tempData });
    } else if (newData && !newId) {
      form.setFieldsValue({ using: undefined });
      form.resetFields();
    }
  };

  const addItem = (name: string) => {
    if (name === "") {
      return;
    }
    const id = v4();
    const newItem = { id: id, name: name, info: initialData };
    if (data && data?.data) {
      setData({ ...data, data: [...data?.data, newItem] });
    } else {
      setData({
        usingId: id,
        data: [newItem],
      });
    }
    setNewItemName("");
  };

  const deleteItem = () => {
    const id: string | undefined = form.getFieldValue("using");
    if (!data || !id) {
      return;
    }
    const tempData = data.data?.filter((item) => item.id !== id);

    updateSetting({
      classify: {
        usingId: undefined,
        data: tempData?.length !== 0 ? tempData : undefined,
      },
    });
  };

  const onFinish = (value: SettingFormProps) => {
    if (!data) {
      message.warning("请先设置配置");
      return;
    }
    const sendData = {
      classify: {
        usingId: value.using,
        data: data.data?.map((item) => {
          if (item.id === value.using) {
            return {
              ...item,
              info: {
                createTask: {
                  text_video_speaking_speed: value.text_video_speaking_speed,
                  text_video_subtitle: value.text_video_subtitle,
                  text_video_bgc_music: value.text_video_bgc_music,
                  text_video_voice: value.text_video_voice,
                },
                createSelectTask: {
                  text_select_video_speaking_speed:
                    value.text_select_video_speaking_speed,
                  text_select_video_subtitle: value.text_select_video_subtitle,
                  text_select_video_bgc_music:
                    value.text_select_video_bgc_music,
                  text_select_video_voice: value.text_select_video_voice,
                },
                dubbingTask: {
                  dubbing_speaking_speed: value.dubbing_speaking_speed,
                  dubbing_voice: value.dubbing_voice,
                },
                createPPTTask: {
                  ppt_speaking_speed: value.ppt_speaking_speed,
                  ppt_subtitle: value.ppt_subtitle,
                  ppt_bgc_music: value.ppt_bgc_music,
                  ppt_voice: value.ppt_voice,
                },
                translateTask: {
                  translate_speaking_speed: value.translate_speaking_speed,
                  translate_voice: value.translate_voice,
                  translate_model: value.translate_model,
                  translate_term: value.translate_term,
                  translate_subtitle: value.translate_subtitle,
                },
                videoToTextTask: {
                  video_to_text_voice: value.video_to_text_voice,
                  video_to_text_term: value.video_to_text_term,
                },
              },
            };
          }
          return item;
        }),
      },
    };

    updateSetting(sendData);
  };

  useEffect(() => {
    if (!props.open) {
      form.resetFields();
    } else {
      if (Object.keys(props.data ?? {}).length !== 0) {
        const tempData = props.data as SettingListProps;
        setData(tempData);
        onFormValuesChange(tempData, tempData.usingId);
      } else {
        setData(undefined);
        form.resetFields();
      }
    }
  }, [props.open, props.data, form]);

  return {
    languageList,
    form,
    data,
    updateLoading,
    newItemName,
    termSelectOptionsValues,
    addItem,
    deleteItem,
    onFinish,
    setNewItemName,
    onFormValuesChange,
  };
};

export default useAction;
