import { useLocation } from "react-router-dom";
import {
  SettingStyleDataProps,
  useAigcVideoStore,
  useVideoStore,
} from "@/store";
import { useEffect, useState } from "react";
import { useRequest } from "ahooks";
import { getSettingListApi } from "@/services/setting";
import { getVoiceApi } from "@/services";
import { SettingListProps } from "@/services/setting/props";
import { MenuList } from "./component/menu";
import { getLanguageListApi } from "@/services/common";

const useAction = () => {
  const [mytheme, setMyTheme] = useAigcVideoStore((state) => [
    state.myTheme,
    state.setMyTheme,
  ]);
  const [setVoice, setLanguageList, setSettingStyleData] = useVideoStore(
    (state) => [
      state.setVoice,
      state.setLanguageList,
      state.setSettingStyleData,
    ]
  );

  const location = useLocation();
  const [settingOpen, setSettingOpen] = useState(false);
  const [selectKey, setSelectKey] = useState<string[]>([]);
  const [menuData, setMenuData] = useState(MenuList);

  useRequest(getVoiceApi, {
    onSuccess(result) {
      setVoice(result.data.result);
    },
  });

  const { data: settingData, run: getSettingData } = useRequest(
    getSettingListApi,
    {
      onSuccess(result) {
        if (Object.keys(result.data.result).length !== 0) {
          const list = result.data.result as SettingListProps;
          let newData: SettingStyleDataProps | undefined = undefined;
          list?.data?.some((item) => {
            if (item.id === list.usingId) {
              newData = item.info;
              return true;
            }
            return false;
          });
          if (newData) {
            setSettingStyleData(newData);
          }
        }
      },
    }
  );

  useRequest(getLanguageListApi, {
    onSuccess(result) {
      setLanguageList(
        result.data.languages.map((item) => {
          if (item.key === "none") {
            return {
              label: item.name,
              value: "",
            };
          }
          return {
            label: item.name,
            value: item.key,
          };
        })
      );
    },
  });

  const themeChange = (flag: boolean) => {
    setMyTheme(flag);
  };

  useEffect(() => {
    let path = "";
    const tempMenuData = menuData.map((item, index) => {
      return {
        ...item,
        children: item.children.map((cItem, cIndex) => {
          // 查找相关路径是否在url中
          const loactionPath = location.pathname + location.search;
          if (loactionPath.includes(MenuList[index].children[cIndex].key)) {
            path = loactionPath;
            return { ...cItem, key: loactionPath };
          }
          return cItem;
        }),
      };
    });

    setSelectKey([path]);
    setMenuData(tempMenuData);
  }, [location.pathname, location.search]);

  return {
    mytheme,
    settingOpen,
    settingData,
    selectKey,
    menuData,
    themeChange,
    setSettingOpen,
    getSettingData,
  };
};

export default useAction;
