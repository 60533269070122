import styles from "./index.module.scss";
import useAction from "./hooks";
import {
  LanguageSelect,
  ButtonSelf,
  SelectSubtitle,
  SelectSpeakingSpeed,
  CascaderLanguage,
  ButtonNormal,
  ButtonEnSpellRead,
  ButtonNumSpellRead,
  ButtonNumContinuouRead,
  ButtonNumSpellRead100,
  CardSelf,
} from "@/components";
import { Button, Input, Popover } from "antd";
import { BGM } from "@/lib/constants";
import { InputTextMark } from "@/components";
import VideoRate from "@/components/VideoRate";
import SiderButton from "@/components/SiderButton";
import { memo } from "react";

const CreateTask = memo(({}) => {
  const {
    myTheme,
    inputRef,
    textSelected,
    inputText,
    loading,
    videoName,
    subtitleSelectValue,
    textPassFlag,
    textMarkShow,
    voiceNum,
    open,
    languageNum,
    bgmNum,
    speechRate,
    textSelectedAction,
    generateVideo,
    videoNameAction,
    onChangeText,
    onChangeLanguage,
    onChangeBgm,
    onChangeSubtitle,
    onChangeSpeakingSpeed,
    generateCheck,
    formatText,
    setOpen,
    generateVideoTask,
  } = useAction();

  return (
    <>
      <div className={styles["root"]}>
        {/* <div className={styles["body"]}> */}
        <CardSelf
          className={styles["body"]}
          title={
            <Input
              classNames={{ input: styles["videoName"] }}
              value={videoName}
              bordered={false}
              placeholder="请输入视频名称"
              onChange={({ target }) => videoNameAction(target.value)}
            />
          }
        >
          <InputTextMark
            ref={inputRef}
            height={84}
            placeholder="输入文本（建议一句话不超过200个字）"
            value={inputText}
            onChange={onChangeText}
            onChangeSelection={(selcet) => {
              textSelectedAction(() => selcet);
            }}
          />
          <div className={styles["btnAll"]}>
            <div className={styles["btnAllSon"]}>
              {textMarkShow && (
                <Popover
                  title={null}
                  trigger="click"
                  placement="bottom"
                  content={() => (
                    <div style={{ display: "flex", gap: 10 }}>
                      <ButtonNormal position={textSelected} refs={inputRef} />
                      <ButtonEnSpellRead
                        position={textSelected}
                        refs={inputRef}
                      />

                      {voiceNum !== 100 && (
                        <ButtonNumSpellRead
                          position={textSelected}
                          refs={inputRef}
                        />
                      )}
                      {voiceNum === 100 && (
                        <ButtonNumSpellRead100
                          position={textSelected}
                          refs={inputRef}
                        />
                      )}
                      <ButtonNumContinuouRead
                        position={textSelected}
                        refs={inputRef}
                        disabled={voiceNum === 100}
                      />
                    </div>
                  )}
                >
                  <Button>文本标注</Button>
                </Popover>
              )}
              <Button onClick={formatText}>自动换行</Button>

              <SelectSpeakingSpeed
                value={speechRate}
                onChange={onChangeSpeakingSpeed}
              />
              <SelectSubtitle
                onChange={onChangeSubtitle}
                value={subtitleSelectValue}
              />
              <LanguageSelect
                value={bgmNum}
                onChange={onChangeBgm}
                placeholder="背景音乐"
                data={BGM}
              />
              {
                <CascaderLanguage
                  value={languageNum}
                  onChange={onChangeLanguage}
                  disabled={true}
                />
              }
              <ButtonSelf
                loading={loading}
                btnName="生成"
                onClick={generateVideo}
                flag={generateCheck()}
              />
            </div>
          </div>
          {!textPassFlag && (
            <div className={styles["textWronTip"]}>
              字数超出，请根据需求换行！
            </div>
          )}
        </CardSelf>
        {/* </div> */}
        <p
          className={`${styles["foot-tip"]} ${
            myTheme ? styles["foot-tip-light"] : styles["foot-tip-night"]
          }`}
        >
          提示：每行文本会对应一个视频片段及相应的字幕，请根据需求换行处理
        </p>
      </div>
      <SiderButton onClick={() => setOpen(true)} />
      <VideoRate
        title="创建视频生成任务"
        open={open}
        onClose={() => setOpen(false)}
        page="createPageTask"
        onVideoChange={generateVideoTask}
      />
    </>
  );
});

export default CreateTask;
