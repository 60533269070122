import { useEffect, useState } from "react";
import { message } from "antd";
import { useRequest, useUnmount } from "ahooks";
import { useAigcVideoStore, useVideoStore } from "@/store";
import { useVideoGenerateStateStore } from "@/store/videoGenerateStateLocal";
import { getVideoToTextListApi } from "@/services/videoToTextApi";
import { postTranslateLingoSelectApi } from "@/services";

const useAction = () => {
  // 主题颜色
  const [myTheme] = useAigcVideoStore((state) => [state.myTheme]);

  const [setVideoToTextList] = useAigcVideoStore((state) => [
    state.setVideoToTextList,
  ]);

  // 字幕
  const [languageList, videoToTextData, settingStyleData, setVideoToTextData] =
    useVideoStore((state) => [
      state.languageList,
      state.videoToTextData,
      state.settingStyleData,
      state.setVideoToTextData,
    ]);

  // 存储视频进度列表
  const [videoToTextTask, setVideoToTextTask] = useVideoGenerateStateStore(
    (state) => [state.videoToTextTask, state.setVideoToTextTask]
  );

  const [videoFile, videoFileAction] = useState<
    { name: string; url: string }[]
  >(videoToTextData.currentFile);
  const [uploadFile, uploadFileAction] = useState<any[]>(
    videoToTextData.uploadFile
  );
  const [selectValue, setSelectValue] = useState<string>("");
  const [open, setOpen] = useState(false);

  const [languageNum, languageNumAction] = useState<string>(
    settingStyleData.videoToTextTask.video_to_text_voice
  );

  // 术语库
  const [termSelectOptionsValues, setTermSelectOptionsValues] = useState<
    string[]
  >([]);
  const [termSelectData, setTermSelectData] = useState<string>(
    settingStyleData.videoToTextTask.video_to_text_term
  );

  const { run: getTermData } = useRequest(postTranslateLingoSelectApi, {
    manual: true,
    onSuccess(result) {
      setTermSelectOptionsValues(result.data.model);
    },
  });

  // 检查配置
  const generateCheck = () => {
    if (
      !!videoFile.length &&
      typeof languageNum !== "undefined" &&
      typeof termSelectData !== "undefined"
    ) {
      return true;
    }
    return false;
  };

  const onSelectChange = (value: string) => {
    languageNumAction(value);
  };

  const { run: generateVideoToText, loading: generateVideoToTextLoading } =
    useRequest(getVideoToTextListApi, {
      manual: true,
      onSuccess(result) {
        console.log(result);
        message.success("成功生成!");

        setVideoToTextTask({
          ...videoToTextTask,
          video: {
            ...videoToTextTask.video,
            tasks: [
              ...videoToTextTask.video.tasks,
              ...result.data.result.map((item) => ({
                id: item.task_id,
                name: item.name,
                isGenerate: false,
                state: result.data.status ?? 0,
                time: item.task_time,
                videoUrl: item.url,
              })),
            ],
          },
        });
      },
      onError(e) {
        message.error("生成失败!");
      },
    });

  // 生成视频
  const generate = () => {
    if (languageNum !== undefined && languageNum !== null) {
      generateVideoToText({
        source: videoFile,
        language: languageNum,
        model: termSelectData === "null" ? [] : [termSelectData],
      });
    }
  };

  const getVideoData = (url: string, name: string) => {
    setVideoToTextList(url, name);
  };

  // 页面销毁缓存
  useUnmount(() => {
    setVideoToTextData({
      ...videoToTextData,
      currentFile: videoFile,
      uploadFile: uploadFile,
    });
  });

  useEffect(() => {
    languageNumAction(settingStyleData.videoToTextTask.video_to_text_voice);
    setTermSelectData(settingStyleData.videoToTextTask.video_to_text_term);
  }, [settingStyleData.videoToTextTask]);

  useEffect(() => {
    getTermData({ provider_id: 1 });
  }, []);

  return {
    myTheme,
    languageList,
    uploadFile,
    open,
    selectValue,
    videoFile,
    languageNum,
    setSelectValue,
    setOpen,
    onSelectChange,
    generate,
    videoFileAction,
    uploadFileAction,
    generateCheck,
    getVideoData,
    generateVideoToTextLoading,
    termSelectOptionsValues,
    setTermSelectOptionsValues,
    termSelectData,
    setTermSelectData,
  };
};

export default useAction;
