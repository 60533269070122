import { useEffect, useState } from "react";
import { message, type UploadProps } from "antd";
import { useRequest, useUnmount } from "ahooks";
import { Env } from "@/config/env";
import {
  postDownLoadSubtitleApi,
  postVideoDubbingApi,
  updateDubbingDataApi,
} from "@/services";
import { useAigcVideoStore, useVideoStore } from "@/store";
import { useNavigate } from "react-router-dom";
import { textReplace } from "@/components";
import { AudioDubbingProps } from "@/components/VideoRate/props";
import { useVideoGenerateStateStore } from "@/store/videoGenerateStateLocal";
import { downFile } from "@/utils/util";

interface AudioListProp {
  id: number;
  state: boolean;
  text: string;
  start: string;
  end: string;
}

const useAction = () => {
  // 存储视频
  const [myTheme, setTextVideoList] = useAigcVideoStore((state) => [
    state.myTheme,
    state.setTextVideoList,
  ]);

  // 字幕
  const [videoDubbingAudioData, setVideoDubbingAudioData, languageList] =
    useVideoStore((state) => [
      state.videoDubbingAudioData,
      state.setVideoDubbingAudioData,
      state.languageList,
    ]);

  // 存储视频进度列表
  const [videoDubbingAudioTask, setVideoDubbingAudioTask] =
    useVideoGenerateStateStore((state) => [
      state.videoDubbingAudioTask,
      state.setVideoDubbingAudioTask,
    ]);

  const [audioList, audioListAction] = useState<AudioListProp[]>(() =>
    videoDubbingAudioData.source.map((item, index) => {
      return {
        ...item,
        id: index,
        state: true,
      };
    })
  );

  const [uploadFile, uploadFileAction] = useState<any[]>(
    videoDubbingAudioData.uploadFile
  );

  const [videoUrl, videoUrlAction] = useState<string>("");
  const [loadingGenerate, loadingGenerateAction] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectLanguage, setSelectLanguage] = useState<string>("zh");
  const navigate = useNavigate();

  // 上传视频文件配置
  const uploadProps: UploadProps = {
    name: "upload_file",
    multiple: true,
    accept: ".mp4, .wav",
    action: `${Env.url}/api/file/save_video`,
    headers: {
      "X-API-KEY": Env.apiKey,
      Accept: "application/json",
    },
    maxCount: 1,
    showUploadList: true,
    fileList: uploadFile,
    beforeUpload(file) {
      const file_name = file.name;
      const new_file = new File([file], file_name);
      return Promise.resolve(new_file);
    },
    onChange(info) {
      const { status } = info.file;
      videoUrlAction(() => "");

      if (status !== "uploading") {
        uploadFileAction(() => []);
        videoUrlAction(() => "");
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
        videoUrlAction(info.file.response.url);
      } else if (status === "error") {
        videoUrlAction(() => "");
        message.error(`${info.file.name} file upload failed.`);
      }
      uploadFileAction(() => info.fileList);
    },
  };

  const { run: updateDubbingData, loading: updateDubbingDataLoading } =
    useRequest(updateDubbingDataApi, {
      manual: true,
      onSuccess() {
        message.success("保存成功");
      },
    });

  const { loading: getSubtitleFileSrcLoading, run: getSubtitleFileSrc } =
    useRequest(postDownLoadSubtitleApi, {
      manual: true,
      onSuccess(result) {
        downFile(result.data.result);
      },
    });

  const audioListChange = (newAudioList: AudioListProp[]) => {
    audioListAction(() => newAudioList);
  };

  // 检查配置项
  const generateCheck = () => {
    const timeflag = audioList.every((item) => item.state);
    if (timeflag) {
      return true;
    }
    return false;
  };

  // 生成视频
  const generateVideo = () => {
    loadingGenerateAction(true);

    const params = {
      video: videoUrl !== "" ? videoUrl : [],
      voice: videoDubbingAudioData.voice,
      speech_rate: videoDubbingAudioData.speech_rate,
      source: audioList.map((item) => {
        return {
          text: textReplace(item.text),
          start: item.start,
          end: item.end,
        };
      }),
    };

    generateVideoRequest.run(params);
  };

  const generateVideoRequest = useRequest(postVideoDubbingApi, {
    manual: true,
    onSuccess(result) {
      loadingGenerateAction(false);
      message.success("成功生成");

      setVideoDubbingAudioTask({
        ...videoDubbingAudioTask,
        video: {
          tasks: [
            ...videoDubbingAudioTask.video.tasks,
            {
              id: result.data.task_id,
              name: videoDubbingAudioData.name,
              isGenerate: false,
              state: 0,
            },
          ],
        },
      });
    },

    onError(e) {
      loadingGenerateAction(false);
      message.error("生成失败");
    },
  });

  /**
   * 抽屉生成任务完成时调用，用于传入数据
   */
  const getDubbingData = (
    name: string,
    data: AudioDubbingProps,
    voiceList: (string | number)[],
    task_id: string,
    status: number
  ) => {
    setVideoDubbingAudioData({
      ...videoDubbingAudioData,
      name,
      voiceList,
      voice: data.voice,
      speech_rate: data.speech_rate,
      source: data.subtitles,
      uploadFile: [],
      srt_file: data.srt_file,
      taskMsg: {
        task_id,
        status,
      },
    });

    uploadFileAction(() => []);
    navigate("/CreateVideoDubbingRoute/videoDubbing/videoPreview");
  };

  const getVideoData = (url: string, name: string) => {
    setTextVideoList(url, name);
  };

  const updateCurrentDubbingData = () => {
    updateDubbingData({
      task_id: videoDubbingAudioData.taskMsg?.task_id ?? "",
      status: videoDubbingAudioData.taskMsg?.status ?? 2,
      result: {
        speech_rate: videoDubbingAudioData.speech_rate,
        srt_file: videoDubbingAudioData.srt_file,
        voice: videoDubbingAudioData.voice,
        subtitles: audioList.map((item) => {
          return {
            text: textReplace(item.text),
            start: item.start,
            end: item.end,
          };
        }),
      },
    });
  };

  useUnmount(() => {
    setVideoDubbingAudioData({
      ...videoDubbingAudioData,
      uploadFile,
      srt_file: videoUrl,
      source: audioList.map((item) => ({
        text: item.text,
        start: item.start,
        end: item.end,
      })),
    });
  });

  useEffect(() => {
    const newAudioList = JSON.parse(
      JSON.stringify(
        videoDubbingAudioData.source.map((item, index) => {
          return {
            ...item,
            id: index,
            state: true,
          };
        })
      )
    );
    audioListAction(newAudioList);
    uploadFileAction(videoDubbingAudioData.uploadFile);
  }, [videoDubbingAudioData]);

  return {
    myTheme,
    loadingGenerate,
    uploadProps,
    audioList,
    open,
    videoDubbingAudioData,
    updateDubbingDataLoading,
    selectLanguage,
    getSubtitleFileSrcLoading,
    languageList,
    generateVideo,
    audioListChange,
    generateCheck,
    setOpen,
    getDubbingData,
    getVideoData,
    updateCurrentDubbingData,
    setSelectLanguage,
    getSubtitleFileSrc,
  };
};

export default useAction;
