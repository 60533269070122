import { FC } from "react";
import { PlusCircleOutlined } from "@ant-design/icons";
import {
  ButtonSelf,
  LanguageSelect,
  CascaderLanguage,
  SelectSpeakingSpeed,
  SelectSubtitle,
  ViewCard,
  textReplace,
  CardSelf,
} from "@/components";
import { BGM } from "@/lib/constants";
import styles from "./index.module.scss";
import { DataLayoutProp } from "./props";
import useAction from "./hooks";

const DataLayout: FC<DataLayoutProp> = ({
  inputText,
  videoName,
  videoData,
  onSelectDel,
}) => {
  const {
    videoDataAwait,
    loading,
    subtitleSelectValue,
    myTheme,
    bgmNum,
    languageNum,
    speechRate,
    videoDataAwaitAction,
    generateVideo,
    onChange,
    onChangeBgm,
    onChangeSubtitle,
    onChangeSpeakingSpeed,
    generateCheck,
  } = useAction();

  const textTip = textReplace(inputText);

  return (
    <div className={styles["root"]}>
      {/* 推荐 */}
      <div className={styles["content"]}>
        <div
          className={`${styles["title"]} ${
            !myTheme && styles["word-color-night"]
          }`}
        >
          推荐
        </div>
        <CardSelf className={styles["recommendContent"]}>
          <div className={styles["videoAll"]}>
            {videoData.map((item, index) => {
              return (
                <ViewCard
                  key={index}
                  isExpand={true}
                  videoSrc={item.url}
                  checkComponent={
                    <PlusCircleOutlined
                      style={{
                        fontSize: "30px",
                        color: "rgb(255,255,255)",
                      }}
                    />
                  }
                  onCheckedChange={() => {
                    videoDataAwaitAction([
                      ...videoDataAwait,
                      { text: textTip, video: item.url },
                    ]);
                    onSelectDel();
                  }}
                />
              );
            })}
          </div>
        </CardSelf>
      </div>
      {/* 已选 */}
      {videoDataAwait.length !== 0 && (
        <div className={styles["content"]}>
          <div
            className={`${styles["title"]} ${
              !myTheme && styles["word-color-night"]
            }`}
          >
            已选
          </div>
          <div className={styles["videoAll"]}>
            {videoDataAwait.map((item, index) => {
              return (
                <ViewCard
                  key={item.video}
                  isExpand={true}
                  onDelete={() => {
                    const awaitArr = videoDataAwait;
                    awaitArr.splice(index, 1);
                    videoDataAwaitAction([...awaitArr]);
                  }}
                  videoSrc={item.video}
                />
              );
            })}
          </div>
          <div className={styles["btn"]}>
            <div className={styles["btnSon"]}>
              <SelectSpeakingSpeed
                value={speechRate}
                onChange={onChangeSpeakingSpeed}
              />
              <SelectSubtitle
                onChange={onChangeSubtitle}
                value={subtitleSelectValue}
              />
              <LanguageSelect
                onChange={onChangeBgm}
                placeholder="背景音乐"
                data={BGM}
                value={bgmNum}
              />
              <CascaderLanguage
                value={languageNum}
                onChange={onChange}
                disabled={true}
              />
              <ButtonSelf
                btnName="生成"
                flag={generateCheck()}
                loading={loading}
                onClick={() => generateVideo(videoName)}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DataLayout;
