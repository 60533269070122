import { Select, Tag } from "antd";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";
import { SelectSubtitleProps } from "./props";
import { useEffect, useMemo, useState } from "react";
import { useVideoStore } from "@/store";

export const SelectSubtitle = (props: SelectSubtitleProps) => {
  const [languageList] = useVideoStore((state) => [state.languageList]);
  const secondSubtitleList = useMemo(() => {
    return languageList.map((item) => ({
      label: item.label,
      value: item.value + "-second",
    }));
  }, [languageList]);
  const [value, setValue] = useState<string[]>([]);
  const selectValue = useMemo(() => {
    if (props.value) {
      if (props.value.length === 2) {
        return [props.value[0], props.value[1] + "-second"];
      }
      return props.value;
    }
    return value;
  }, [props.value, value]);

  const onChange = (newValue: string[]) => {
    if (newValue.length === 2) {
      const secondValue = newValue[1].split("-")[0];
      props.onChange?.([value[0], secondValue]);
    } else if (newValue.length === 1 && newValue[0].endsWith("-second")) {
      props.onChange?.([]);
      setValue([]);
      return;
    } else {
      props.onChange?.(newValue);
    }
    setValue(newValue);
  };

  useEffect(() => {
    if (props.value) {
      if (props.value.length === 2) {
        setValue([props.value[0], props.value[1] + "-second"]);
      } else {
        setValue(props.value);
      }
    }
  }, [props.value]);

  return (
    <Select
      allowClear
      mode="multiple"
      style={{
        width: "120px",
      }}
      showSearch={false}
      placeholder={"选择字幕"}
      tagRender={(props: CustomTagProps) => {
        const { label } = props;
        return (
          <Tag
            style={{
              marginRight: 1,
            }}
          >
            <span>{label}</span>
          </Tag>
        );
      }}
      value={selectValue}
      onChange={onChange}
      options={[
        {
          label: "字幕（一）",
          options: languageList.map((item) => {
            let disabled = false;
            if (selectValue[0] !== undefined && selectValue[0] !== item.value) {
              disabled = true;
            }
            return {
              value: item.value,
              label: item.label,
              disabled,
            };
          }),
        },
        {
          label: "字幕（二）",
          options: secondSubtitleList.map((item) => {
            let disabled = false;
            if (
              selectValue[0] === "" ||
              selectValue.length === 0 ||
              (selectValue.length === 2 && selectValue[1] !== item.value)
            ) {
              disabled = true;
            }
            return {
              value: item.value,
              label: item.label,
              disabled: disabled,
            };
          }),
        },
      ]}
    />
  );
};
